import axios from 'axios'
import store from '@/store'
import router from '@/router'

import {Decrypt,Encrypt} from './utils.js';

import { ElMessageBox, ElMessage } from 'element-plus'
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: '/api', 
  timeout: 20000 // request timeout
})
 
// 请求拦截器
service.interceptors.request.use(
  config => {

    config.headers['Banben'] = '1.0';
    config.headers['Device'] = '4';
    config.headers['Deviceid'] = '1';
    config.headers['Timestamp'] = '1';

    let str = config.url;
    // console.log(str.split('/'));
    if( str.split('/')[2] == "login" ){
      // console.log('不需要token');
      config.headers['token'] = 'yun';
    }else{
      if (store.state.token) {
        config.headers['token'] = store.state.token;
      }else{
        // console.log('无token');
      }
    }
	
    if( str.split('=')[1]){
      config.url = str.split('=')[0] +'=' + Encrypt( str.split('=')[1]  ) ;
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   * responseType: 'blob'
   */
  response => {
    // console.log(response,'111');

    const res = response.data;

    // console.log(res,'111');

    if(res.status==200){
      if(res.data){
        res.data =JSON.parse( Decrypt(res.data));
        // res.data = Decrypt(res.data);

        if(res.p){
          return res;
        }else{
          if(res.data){
            return res.data ;
          }else{
            return res ;
          }
        }
      }else{
          return res;
      }
    }else{
      if(response.headers['content-type'] == "image/png"){
        return res
      }else{
        console.error(res,'111')
        if(res.status==401){
          store.commit('setToken', '');
          router.push('/login');
          ElMessage({
            message: '登录失效，请重新登录！',
            type: 'error',
            duration: 5 * 1000
          })
        }else{
          ElMessage.error(res.message);
        }
        return '';

      }
        
    }
  },
  error => {
    console.log('err' + error) // for debug
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
